<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate"
        >新增</el-button
      >
      <el-input
        v-model="listQuery.name"
        placeholder="请输入课程名称"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <!-- <el-input v-model="listQuery.tearcher_name" placeholder="请输入教师名称" style="width: 200px;" class="filter-item" clearable/> -->
      <!-- <el-date-picker
        v-model="listQuery.school_year"
        type="year"
        class="filter-item"
        value-format="yyyy"
        placeholder="选择学年">
      </el-date-picker>

      <el-select v-model="listQuery.semester" placeholder="请选择学期" clearable style="width: 150px" class="filter-item">
        <el-option label="第一学期" :value="1"/>
        <el-option label="第二学期" :value="2"/>
      </el-select> -->

      <el-select
        v-model="listQuery.college_id"
        placeholder="请选择院系"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option
          v-for="(v, i) in colleges"
          :key="i"
          :label="v.name"
          :value="v.id"
        ></el-option>
      </el-select>

      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="院系名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.college.name }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="学年" min-width="110" align="center">
        <template slot-scope="scope">
        </template>
      </el-table-column> -->
      <!-- <el-table-column label="学期" min-width="110" align="center">
        <template slot-scope="scope">
        </template>
      </el-table-column> -->
      <!-- <el-table-column label="课程类型" min-width="110" align="center">
        <template slot-scope="scope">
        </template>
      </el-table-column> -->
      <el-table-column label="课程名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDel(scope.row.id)"
            v-if="scope.row.is_schedule == 0"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="请选择院系" prop="college_id">
          <el-select
            v-model="form.college_id"
            placeholder="请选择院系"
            style="width: 100%"
          >
            <el-option
              v-for="item in colleges"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="请选择学年" prop="school_year">
          <el-date-picker
            v-model="form.school_year"
            type="year"
            class="filter-item"
            value-format="yyyy"
            placeholder="选择学年">
          </el-date-picker>
        </el-form-item> -->

        <el-form-item label="课程名称" prop="name">
          <el-input type="text" v-model="form.name" clearable />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      colleges: [],
      listQuery: {
        page: 1,
        limit: 10,
        name: "",
        tearcher_name: "",
        school_year: "",
        semester: "",
        college_id: "",
      },
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: { update: "修改", create: "新增" },
      btnLoading: false,
      form: {
        id: "",
        college_id: "",
        school_year: "",
        name: "",
      },
      rules: {
        college_id: [
          { required: true, message: "院系不能为空", trigger: "change" },
        ],
        school_year: [
          { required: true, message: "学年不能为空", trigger: "change" },
        ],
        name: [
          { required: true, message: "课程名称不能为空", trigger: "change" },
        ],
      },
      college_ids: [], // 院系
    };
  },
  created() {
    this.getCollegeList();
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/schoolend/course/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getCollegeList() {
      request({
        url: "/api/schoolend/school/collegeList",
        method: "get",
        params: {
          limit: 999999999999999,
        },
      }).then((response) => {
        this.colleges = response.data.data;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm() {
      this.form = {
        id: "",
        name: "",
      };
    },
    handleCreate() {
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    handleUpdate(row) {
      this.resetForm();
      this.form = Object.assign({}, row);
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/schoolend/course/store",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    handleDel(val) {
      this.$confirm("此操作将删除该课程, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/schoolend/course/del",
            method: "post",
            params: { school_course_id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 获取省
    getProvince() {
      return request({
        url: "/api/common/area/index",
        method: "get",
      }).then((res) => {
        this.provinces = res.data;
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
